import { Box, Typography } from "@mui/material";

export default function Footer() {
  return(
    <Box 
      component="footer"
      sx={{
        color:  { xs: "var(--text-60-black, #81858F)", md: "#000" },
        backgroundColor: "#F7F8FE",
      }}
    >
      <Typography m={{ xs: "0.87rem 1.56rem", md: "1.25rem" }} fontSize={{ xs: "9px", md: "14px" }} fontFamily="Inter">
        Cookiejar Technologies Pvt Ltd. is a registered and licensed NBFC Account Aggregator (NBFC-AA) from the Reserve Bank of India (RBI) and has the operational license for the business of account aggregation. Finvu AA is a brand of Cookiejar Technologies Pvt Ltd
      </Typography>
    </Box>
  )
}