import { Box, Typography, Divider, IconButton, Button, Avatar } from "@mui/material";
import Footer from "../components/Footer";
import Header from "../components/Header";
import finvuText from "../assets/Images/finvuText.svg";
import GFF23 from "../assets/Images/GFF23.png";
import { ArrowDownward, SaveAlt, Description } from '@mui/icons-material';
// import YouTube from 'react-youtube';

export default function Home() {
  
  const videoId = 'OtJFf_JfecA';

  // const opts = {
  //   height: '416',
  //   width: '672',
  //   playerVars: {
  //     // https://developers.google.com/youtube/player_parameters
  //     autoplay: 1, // Set to 1 for autoplay
  //     mute: 1,
  //   },
  // };

  const iframeSrc = `https://www.youtube.com/embed/${videoId}?autoplay=0&rel=0`;

  // const optsMob = {
  //   height: '173',
  //   width: "100%",
  //   playerVars: {
  //     // https://developers.google.com/youtube/player_parameters
  //     autoplay: 1, // Set to 1 for autoplay
  //     mute: 1,
  //   },
  // };

  const downloadBroucher = () => {
    const link = document.createElement('a');
    link.href = 'Finvu_Broucher.pdf'; // Path to PDF file
    link.download = 'Finvu_Broucher.pdf'; // Name of the downloaded file
    link.click();
  }

  const downloadVideo = () => {
    const link = document.createElement('a');
    link.href = 'finvu.mp4'; // Path to Video file
    link.download = 'Finvu.mp4'; // Name of the downloaded file
    link.click();
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: "100%" }}>
      <Header />
      {/* PC View */}
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "row",
          flex: 1, 
          backgroundImage: 'url("backGroundPC.png")', // Replace with the actual path to your image
          backgroundSize: "cover", // Adjust the size as needed
          backgroundPosition: "0px -150px", // Manually specify the position (horizontal and vertical)
          // background: "linear-gradient(to right, #0B0035, #03508F)", // #03508F 001556
          pt: "90px", 
          px: "60px",
        }}
      >
        <Box sx={{ flexDirection: "columnn", flex: 1 }}>
          <Typography 
            sx={{ 
              color: "#6564A1", 
              fontFamily: "Krona One", 
              fontSize: "82px",
            }}
          >
            Welcome to
          </Typography>
          <Box sx={{ display: "flex", mt: "20px", flexDirection: "row", alignItems: "center" }}>
            <Avatar 
              src={finvuText} 
              alt="FinvuText" 
              sx={{ 
                width: "303.178px", 
                height: "64.322px", 
                borderRadius: 0,
              }}
            />
            <Divider 
              sx={{ 
                bgcolor: "#FFFFFF", 
                border:"2px solid white", 
                mx: "55px",
                my: "15px",
              }} 
              flexItem
            />
            <Avatar 
              src={GFF23}
              alt="FinvuText" 
              sx={{ 
                width: "207px",
                position: "relative",
                right: 0, 
                height: "122px", 
                borderRadius: 0 
              }}
            />
          </Box>
          <Typography sx={{ color: "#BEB3D4", fontFamily: "Roboto", maxWidth: "665px", mt: "40px", fontSize: "20px" }} >
            Consent based data sharing in a secured ecosystem | 
            10M+ registered consumer handles and 50+ BFSI institutions as partners using our platform | 
            A flexible system created with an objective to align to your processes | 
            We adapt and design your system as per your product team's preference
          </Typography>
          <Box sx={{ display: "flex", mt: "50px" }}>
            <Button
              href="https://play.google.com/store/apps/details?id=com.finvu"
              target="_blank"
              sx={{
                width: "308px",
                height: "55px",
                borderRadius: "140px",
                border: "2px solid #2D0077",
                background: "linear-gradient(92deg, #FFF 5.64%, #EAE8FD 98.66%)",
                display: "flex",            
                alignItems: "center",      
                justifyContent: "space-between",
                mr: "14px",
              }}
            >
              <Typography 
                sx={{
                  ml: "20px", fontFamily: "Roboto", letterSpacing: "1.6px", background: 'linear-gradient(91deg, #001556 0%, #03508F 99.75%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent' 
                }}
              >
                DOWNLOAD FINVU APP
              </Typography>
              <IconButton
                sx={{
                  position: "relative",
                  right: "-5px",
                  color: "white",
                  borderRadius: "140px",
                  background: "#2245AF",
                  width: "47px",
                  height: "47px",
                  flexShrink: 0,
                  "&:hover": {
                    bgcolor: "#2245AF",
                  },
                }}
              >
                <ArrowDownward />
              </IconButton>
            </Button>
            <Button
              onClick={downloadBroucher}
              sx={{
                width: "308px",
                height: "57px",
                borderRadius: "140px",
                border: "2px solid #4030F4",
                color: "var(--White, #FFF)",
              }}
            >
              <Description sx={{ mb: "2px", mr: "8px" }}/>
              <Typography sx={{ fontFamily: "Roboto", fontSize: "1rem", letterSpacing: "1.6px" }}>
                DOWNLOAD BROCHURE
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", px: "150px", mt: "30px" }}>
          <Box
            sx={{
              width: "700px",
              height: "444px",
              borderRadius: "14px",
              background: "rgba(218, 145, 252, 0.3)", 
              backdropFilter: "blur(82px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ borderRadius: '14px', overflow: "hidden" }}>
              {/* <YouTube videoId={videoId} opts={opts}/> */}
              <iframe
                width="672"
                height="416"
                src={iframeSrc}
                allowFullScreen
                title="Finvu"
              ></iframe>
            </div>
          </Box>
          <Box sx={{ width: "700px", mt: "10px", display: "flex", justifyContent: "flex-end" }}>
            <Button 
              onClick={downloadVideo} 
              sx={{ 
                fontWeight: 700, 
                letterSpacing: "1.6px", 
                color: "#FFFFFF", 
                fontFamily: "Roboto" 
              }}
            >
              <SaveAlt sx={{ mr: "7px" }}/>
              Download Video
            </Button>
          </Box>
        </Box>
      </Box>



      {/* Mobile View */}
      <Box 
        sx={{ 
          display: { xs: "flex", md: "none" }, 
          flexDirection: "column", 
          flex: 1, 
          backgroundImage: 'url("backGroundMobile.png")', // Replace with the actual path to your image
          backgroundSize: "cover", // Adjust the size as needed
          // background: `linear-gradient(to bottom, #0B0035, #03508F), linear-gradient(to right, #0B0035, #03508F)`,
          // backgroundColor: "#0B0035", 
          pt: "38px", 
          px: "25px", 
        }}
      >
        <Typography
          variant="h5"
          sx={{
            position: 'absolute',
            top: '90%',
            left: '50%',
            fontSize: "130px",
            fontWeight: 600,
            opacity: "0.3",
            transform: 'translate(-50%, -50%)',
            color: 'rgba(255, 255, 255, 0.5)', // Text color with opacity
            pointerEvents: 'none', // Allows interactions with elements below
            letterSpacing: "2px",
          }}
        >
          FINVU
        </Typography>
        <Typography   
          sx={{ 
            color: "#6564A1", 
            fontFamily: "Krona One", 
            fontSize: "42px",
          }}
        >
          Welcome to
        </Typography>
        <Box sx={{ mt: "1=5px", display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Avatar 
            src={finvuText}
            alt="FinvuText" 
            sx={{ 
              position: "relative",
              left: 0,
              width: "157.024px", 
              height: "32.721px", 
              borderRadius: 0,
            }}
          />
          <Divider sx={{ bgcolor: "#FFFFFF", mt: "6px", border: "1px solid white", mx: "37px" }} flexItem />
          <Avatar 
            src={GFF23} 
            alt="FinvuText" 
            sx={{
              position: "relative",
              right: 0,
              width: "106px", 
              height: "62px", 
              borderRadius: 0, 
              color: "#FFFFFF" 
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", mt: "40px" }}>
          <Box
            sx={{
              width: "100%",
              height: "174px",
              borderRadius: "7.712px",
              background: "rgba(218, 145, 252, 0.3)",
              backdropFilter: "blur(82px)",
              display: "flex",
            }}
          >
            <div
              style={{ 
                borderRadius: '7.712px', 
                overflow: "hidden", 
                width: "100%", 
                border: "4px solid rgba(218, 145, 252, 0.3)" 
              }}
            >
              {/* <YouTube videoId={videoId} opts={optsMob} /> */}
              <iframe
                width="100%"
                height="173"
                src={iframeSrc}
                allowFullScreen
                title="Finvu"
              ></iframe>
            </div>
          </Box>
          <Box sx={{ width: "100%", mt: "5px", display: "flex", justifyContent: "flex-end" }}>
            <Button 
              onClick={downloadVideo} 
              sx={{ 
                fontWeight: 700, 
                letterSpacing: "1.2px", 
                color: "#FFFFFF", 
                fontFamily: "Roboto", 
                fontSize: "12px" 
              }}
            >
              <SaveAlt sx={{ mr: "7px" }} />
              Download Video
            </Button>
          </Box>
          <Typography sx={{ fontFamily: "Roboto", color: "#BEB3D4", fontSize: "12px", maxWidth: "100%", mt: "10px" }} >
            Consent based data sharing in a secured ecosystem |
            10M+ registered consumer handles and 50+ BFSI institutions as partners using our platform |
            A flexible system created with an objective to align to your processes |
            We adapt and design your system as per your product team's preference
          </Typography>
          <Box sx={{ display: "flex", mt: "24px" }}>
            <Button
              href="https://play.google.com/store/apps/details?id=com.finvu"
              target="_blank"
              sx={{
                width: "110%",
                height: "40px",
                borderRadius: "70.455px",
                border: "2px solid #2D0077",
                background: "linear-gradient(92deg, #FFF 5.64%, #EAE8FD 98.66%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mr: "20px",
              }}
            >
              <Typography 
                sx={{
                  ml: "10px", 
                  fontFamily: "Roboto",
                  letterSpacing: "0.5px",
                  fontSize: "10px", 
                  fontWeight: 700, 
                  background: 'linear-gradient(91deg, #001556 0%, #03508F 99.75%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent' 
                }} 
              >
                DOWNLOAD FINVU APP
              </Typography>
              <IconButton
                sx={{
                  right: "-3px",
                  color: "white",
                  borderRadius: "70.455px",
                  background: "#2245AF",
                  width: "23.653px",
                  height: "23.653px",
                  flexShrink: 0,
                  "&:hover": {
                    bgcolor: "#2245AF",
                  },
                }}
              >
                <ArrowDownward style={{ fontSize: "1rem" }}/>
              </IconButton>
            </Button>
            <Button
              onClick={downloadBroucher}
              sx={{
                width: "100%",
                height: "42px",
                borderRadius: "70.455px",
                border: "2px solid #4030F4",
                color: "var(--White, #FFF)",
              }}
            >
              <Description sx={{ mr: "2px" }} fontSize="small" style={{ fontSize: "1rem", mr: "2px" }} />
              <Typography sx={{ fontSize: "10px", fontWeight: 700, fontFamily: "Roboto", letterSpacing: "0.5px"  }}>
                DOWNLOAD BROCHURE
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Footer />
    </div>
  )
}