import {
  AppBar,
  Container,
  Toolbar,
  Box,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import finvuLogo from "../assets/Images/finvuLogo.png";

function Header() {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: "#F7F8FE", borderRadius: 0, height: { xs: "3.375rem", md: "5.625rem" }, fill: "#F7F8FE", filter: "drop-shadow(0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.16))" }}>
        <Container maxWidth="xxl" disableGutters sx={{ margin: 0 }}>
          <Toolbar disableGutters>
            {mobileScreen ? (
              <>
                <Box sx={{ ml: "1.56rem", flexGrow: 1, py: "0.44rem" }}>
                  <img src={finvuLogo} height="40" width="80" alt="FinvuLogo" />
                </Box>
                <Box sx={{ position: "absolute", right: "19px", backgroundColor: "var(--Primary, #3053D3)", borderRadius: "0.5rem", mb: "0.2rem" }}>
                  <Button href="https://finvu.in/contactus" target="_blank" sx={{ textTransform: "none", fontFamily: "Roboto", px: "22.86px", fontWeight: 500, color: "var(--White, #FFF)", fontSize: "12px" }}>Contact Us</Button>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ py: "1.25rem", ml: "3.75rem", flex: 1, alignContent: "center", justifyContent: "center" }}>
                    <img src={finvuLogo} height="50" width="100" alt="FinvuLogo" />
                </Box>
                <Box sx={{ position: "absolute", right: "3.75rem", backgroundColor: "var(--Primary, #3053D3)", borderRadius: "0.5rem" }}>
                  <Button href="https://finvu.in/contactus" target="_blank" sx={{ textTransform: "none", fontFamily: "Roboto", px: "3.64rem", fontWeight: 500, color: "var(--White, #FFF)", fontSize: "1rem" }}>Contact Us</Button>
                </Box>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default Header;
